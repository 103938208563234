import { Ref, UnwrapNestedRefs } from 'vue';
import { QueryFilters } from '@/types/services';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'vue-query';
import {
  createInspectionGroup,
  deleteInspectionGroup,
  getInspectionGroup,
  getInspectionGroupReport,
  getInspectionGroups,
  getInspectionGroupsByLastDeviceInspection,
  getInspectionGroupsDetails,
  updateInspectionGroup,
} from '@/entities/inspection-group/services';
import {
  CreateInspectionGroupData,
  DeleteInspectionGroupData,
  GetInspectionGroupByLastDeviceData,
  GetInspectionGroupData,
  GetInspectionGroupReportData,
  InspectionGroup,
  InspectionGroupDetail,
  UpdateInspectionGroupData,
} from '@/entities/inspection-group/types';

import { queryKeyHash } from '@/utils/query';
import { devicesQueryName } from '@/entities/device/hooks';

export const inspectionGroupsQueryName = 'inspectionGroups';

export const useGetInspectionGroupsQuery = (
  options?: UseQueryOptions<InspectionGroup[]>,
  filters?: Ref<QueryFilters<InspectionGroup>>,
) =>
  useQuery(inspectionGroupsQueryName, () => getInspectionGroups(filters?.value), {
    ...options,
    queryKeyHashFn: (key) => queryKeyHash(key, { filters: filters?.value }),
  });

export const useGetInspectionGroupsDetailsQuery = (
  options?: UseQueryOptions<InspectionGroupDetail[]>,
  filters?: Ref<QueryFilters<InspectionGroupDetail>>,
) =>
  useQuery(
    [inspectionGroupsQueryName, 'details', filters],
    () => getInspectionGroupsDetails(filters?.value),
    {
      ...options,
    },
  );
// export const useGetInspectionGroupsDetailsByDeviceGroupQuery = (data: Ref<GetDeviceGroupData>) => useQuery([inspectionGroupsQueryName, data, 'details-by-device-group'], () => getInspectionGroupsDetailsByDeviceGroup(data.value))
// export const useGetInspectionGroupsDetailsByDeviceQuery = (data: Ref<GetDeviceData>) => useQuery([inspectionGroupsQueryName, data, 'details-by-device'], () => getInspectionGroupsDetailsByDevice(data.value))

export const useGetInspectionGroupQuery = (
  data: Ref<GetInspectionGroupData>,
  options?: UseQueryOptions<InspectionGroup>,
) =>
  useQuery([inspectionGroupsQueryName, data], () => getInspectionGroup(data.value), {
    ...options,
    refetchInterval: 10000,
    staleTime: 10000,
  });

export const useGetInspectionGroupsByLastDeviceInspection = (
  data: Ref<GetInspectionGroupByLastDeviceData>,
  options?: UseQueryOptions<InspectionGroup[]>,
) =>
  useQuery(
    [inspectionGroupsQueryName, data, 'by-last-device-inspection'],
    () => getInspectionGroupsByLastDeviceInspection(data.value),
    options,
  );

export const useCreateInspectionGroupMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: UnwrapNestedRefs<CreateInspectionGroupData>) => createInspectionGroup(data),
    {
      onSuccess() {
        queryClient.refetchQueries(devicesQueryName, { active: true });
        queryClient.refetchQueries(inspectionGroupsQueryName, { active: true });
      },
    },
  );
};

export const useUpdateInspectionGroupMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: UnwrapNestedRefs<UpdateInspectionGroupData>) => updateInspectionGroup(data),
    {
      onSuccess({ id }) {
        queryClient.refetchQueries(inspectionGroupsQueryName, { active: true });
        queryClient.refetchQueries([inspectionGroupsQueryName, id], { active: true });
      },
    },
  );
};

export const useDeleteInspectionGroupMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: UnwrapNestedRefs<DeleteInspectionGroupData>) => deleteInspectionGroup(data),
    {
      onSuccess() {
        queryClient.refetchQueries(inspectionGroupsQueryName, { active: true });
      },
    },
  );
};

export const useGetInspectionGroupReportMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: UnwrapNestedRefs<GetInspectionGroupReportData>) => getInspectionGroupReport(data),
    {
      onSuccess() {
        queryClient.refetchQueries(inspectionGroupsQueryName, { active: true });
      },
    },
  );
};
