import { ExtractPropTypes, PropType } from 'vue';

export const sizeValues = ['xxs', 'xs', 'sm', 'md', 'lg'] as const;
export const typeValues = ['primary', 'white', 'gray', 'success', 'danger', 'white-outline'] as const;

export const props = {
  as: {
    type: String,
    default: 'button',
  },
  icon: {
    type: [String, null],
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String as PropType<typeof typeValues[number]>,
    default: 'primary',
  },
  size: {
    type: String as PropType<typeof sizeValues[number]>,
    default: 'md',
  },
  circle: {
    type: Boolean,
    default: false,
  },
  uppercase: {
    type: Boolean,
    default: true,
  },
  rightIcon: {
    type: Boolean,
    default: false,
  },
  plain: {
    type: Boolean,
    default: true,
  },
};

export type Props = ExtractPropTypes<typeof props>;
