import styled from 'vue3-styled-components';
import tokens from '@/styles/tokens';
import { fontSizeStyle } from '@/utils/css-in-js';
import { textProps } from './text.shared';

export const types = {
  default: {
    color: tokens.colors.gray,
    ...fontSizeStyle(tokens.fontSize.xs),
  },
  bold: {
    color: tokens.colors.gray,
    fontWeight: 700,
  },
  'bold-xxs': {
    color: tokens.colors.gray,
    fontWeight: 700,
    ...fontSizeStyle(tokens.fontSize.xxs),
  },
  'bold-xxs2': {
    color: tokens.colors.gray,
    fontWeight: 700,
    ...fontSizeStyle(tokens.fontSize.xxs2),
  },
  semibold: {
    color: tokens.colors.gray,
    fontWeight: 600,
    ...fontSizeStyle(tokens.fontSize.xs),
  },
  'semibold-xxs': {
    color: tokens.colors.gray,
    fontWeight: 600,
    ...fontSizeStyle(tokens.fontSize.xxs),
  },
  'semibold-xxs2': {
    color: tokens.colors.gray,
    fontWeight: 600,
    ...fontSizeStyle(tokens.fontSize.xxs2),
  },
  'semibold-xxs-keep': {
    color: tokens.colors.gray,
    fontWeight: 600,
    ...fontSizeStyle(tokens.fontSize.xxs),
    wordBreak: 'keep-all',
  },
  'semibold-xxs-keep-corpo': {
    color: tokens.colors.primary.DEFAULT,
    fontWeight: 600,
    ...fontSizeStyle(tokens.fontSize.xxs),
    wordBreak: 'keep-all',
  },
  caption: {
    color: tokens.colors.gray['300'],
    ...fontSizeStyle(tokens.fontSize.sm),
    fontWeight: 600,
  },
  'caption-xxs': {
    color: tokens.colors.gray.DEFAULT,
    ...fontSizeStyle(tokens.fontSize.xxs),
    fontWeight: 600,
  },
  'extra-small': {
    color: '#000',
    ...fontSizeStyle(tokens.fontSize.xxs2),
    fontWeight: 700,
  },
  'light-extra-small': {
    ...fontSizeStyle(tokens.fontSize.xxs2),
    fontWeight: 300,
  },
  label: {},
} as const;

export const StText = styled('div', textProps)`
  color: ${ () => tokens.colors.black.DEFAULT };
  text-transform: ${ ({ uppercase }) => uppercase ? 'uppercase' : 'none' };
  ${ ({ type = 'default' }) => types[type] }
  text-align: ${ ({ align }) => align }
`;
