import tokens from '@/styles/tokens';
import styled from 'vue3-styled-components';
import { omit } from 'lodash';
import { rem } from 'polished';
import { ExtractPropTypes } from 'vue';
import { disabledStyle, fontSizeStyle } from '@/utils/css-in-js';
import { props } from './button.shared';

const colorTypes = {
  primary: {
    borderColor: tokens.colors.primary.DEFAULT,
    backgroundColor: tokens.colors.primary.DEFAULT,
    color: tokens.colors.white.DEFAULT,
    '&:hover, &:focus': {
      backgroundColor: tokens.colors.primary['400'],
      borderColor: tokens.colors.primary['400'],
    },
    '&:focus': {
      outlineColor: tokens.colors.primary['500'],
    },
  },
  white: {
    borderColor: tokens.colors.white.DEFAULT,
    backgroundColor: tokens.colors.white.DEFAULT,
    color: tokens.colors.black.DEFAULT,
    '&:hover, &:focus': {
      backgroundColor: tokens.colors.gray['100'],
      borderColor: tokens.colors.gray['100'],
    },
    '&:focus': {
      outlineColor: tokens.colors.black.DEFAULT,
    },
  },
  'white-outline': {
    borderColor: tokens.colors.primary.DEFAULT,
    backgroundColor: tokens.colors.white.DEFAULT,
    color: tokens.colors.primary.DEFAULT,
    '&:hover, &:focus': {
      backgroundColor: tokens.colors.primary.DEFAULT,
      borderColor: tokens.colors.primary.DEFAULT,
      color: tokens.colors.white.DEFAULT
    },
    '&:focus': {
      outlineColor: tokens.colors.black.DEFAULT,
    },
  },
  gray: {
    borderColor: tokens.colors.gray['100'],
    backgroundColor: tokens.colors.gray['100'],
    color: tokens.colors.primary.DEFAULT,
    '&:hover, &:focus': {
      backgroundColor: tokens.colors.gray['300'],
      borderColor: tokens.colors.gray['300'],
    },
    '&:focus': {
      outlineColor: tokens.colors.primary.DEFAULT,
    },
  },
  success: {
    borderColor: tokens.colors.success.DEFAULT,
    backgroundColor: tokens.colors.success.DEFAULT,
    color: tokens.colors.white.DEFAULT,
    '&:hover, &:focus': {
      backgroundColor: tokens.colors.success['300'],
      borderColor: tokens.colors.success['300'],
    },
    '&:focus': {
      outlineColor: tokens.colors.success.DEFAULT,
    },
  },
  danger: {
    borderColor: tokens.colors.danger.DEFAULT,
    backgroundColor: tokens.colors.danger.DEFAULT,
    color: tokens.colors.white.DEFAULT,
    '&:hover, &:focus': {
      backgroundColor: tokens.colors.danger['300'],
      borderColor: tokens.colors.danger['300'],
    },
    '&:focus': {
      outlineColor: tokens.colors.danger.DEFAULT,
    },
  }
};

const styledProps: Omit<typeof props, 'disabled'> & { isDisabled: typeof props.disabled } = {
  ...omit(props, 'disabled'),
  isDisabled: props.disabled,
};
type ExtractedStyleProps = ExtractPropTypes<typeof styledProps>;

const getSizeStyle = ({ size, circle }: ExtractedStyleProps) => {
  const styles = {
    xxs: {
      padding: 0,
      height: rem(12),
      width: rem(12),
      ...fontSizeStyle(tokens.fontSize.xxs2),
    },
    xs: {
      padding: circle ? tokens.spacing['1'] : `0 ${ tokens.spacing['2'] }`,
      height: rem(20),
      width: circle ? rem(20) : 'auto',
      ...fontSizeStyle(tokens.fontSize.xxs2),
    },
    sm: {
      padding: circle ? tokens.spacing['1'] : `0 ${tokens.spacing['2']}`,
      height: rem(33),
      width: circle ? rem(33) : 'auto',
      ...fontSizeStyle(tokens.fontSize.xxs2),
    },
    md: {
      padding: circle ? tokens.spacing['2'] : `0 ${tokens.spacing['3']}`,
      height: rem(40),
      width: circle ? rem(40) : 'auto',
      ...fontSizeStyle(tokens.fontSize.xxs),
    },
    lg: {
      padding: circle ? tokens.spacing['2'] : `0 ${tokens.spacing['4']}`,
      height: rem(40),
      width: circle ? rem(40) : 'auto',
      ...fontSizeStyle(tokens.fontSize.xxs),
    },
  };

  return styles[size];
};

const getColorStyle = ({ type, plain }: ExtractedStyleProps) => {
  const styles = {
    ...Object.fromEntries(
      (Object.keys(colorTypes) as (keyof typeof colorTypes)[]).map((colorKey) => {
        const colorObject = colorTypes[colorKey];

        return [
          colorKey,
          plain
            ? { ...colorObject }
            : {
                ...colorObject,
                backgroundColor: colorObject.color,
                color: colorObject.backgroundColor,
                '&:hover, &:focus': {
                  backgroundColor: colorObject.backgroundColor,
                  color: colorObject.color,
                },
              },
        ];
      }),
    ),
  };

  return styles[type];
};

export const StButton = styled('button', styledProps)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
  transition: background-color .2s ease-in-out, color .2s ease-in-out;
  font-weight: 800;
  ${getSizeStyle};
  ${getColorStyle};
  letter-spacing: ${() => tokens.letterSpacing.DEFAULT};
  border-radius: ${({ circle }) => (circle ? '999rem' : tokens.borderRadius.DEFAULT)}
  outline: transparent 0.188rem solid;
  font-family: 'Open Sans', sans-serif;
  gap: ${() => tokens.spacing['3']};
  cursor: pointer;
  border-width: ${() => rem(1)};
  border-style: solid;
  ${({ isDisabled }) => disabledStyle(isDisabled)}

  svg {
    pointer-events: none;
  }

  // & svg {
  //   height: 1rem;
  //   width: 1rem;
  // }
`;
